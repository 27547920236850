/*
 * 업무구분: 고객
 * 화 면 명: MSPCM002M
 * 화면설명: 필수컨설팅동의
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.05.31
 * 작 성 자: 이태훈
 * 2차 작성자: sh.park2022
 */
<template>
  <ur-page-container title="필수컨설팅동의" :show-title="true" type="subpage" action-type="none" @on-header-left-click="fn_ClickPrev">
    <ur-box-container direction="column" align-v="start" class="ns-agreeArea2">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <div class="agree-box">
          <span class="agree-title">
            소비자 권익보호에 관한 사항
          </span>
          <span class="agree-txt">
            귀하는 개인(신용) 정보의 수집·이용 및 조회, 제공에 관한 동의를 거부하실 수 있으며, 
            개인의 신용도 등을 평가하기 위한 목적 이외의 개인(신용) 정보 제공 동의를 철회할 수 있습니다. 다만, 
            <strong>
              본 동의는 '고객정보 관리 및 가입설계' 를 위해 필수적인 사항이므로 동의를 거부하시는 경우 
              관련 업무수행이 불가능합니다.
            </strong><br>
            ※ 가입설계를 위해 회사에 제공한 정보에 대해서도 실제 청약 시에는 별도로 알릴 의무를 이행하셔야 합니다.
          </span>
        </div>
        <div class="chk-box">
          <div class="chk-title">
            고객정보 및 가입설계를 위한 상세 동의서
          </div>
          <div class="ns-check">
            <mo-checkbox v-model="isAllAgree" @input="fn_SelectAgreeAll">전체동의</mo-checkbox>
            <mo-checkbox v-model="dontAgreeCheckbox" @input="fn_CancelAgreeAll">동의안함</mo-checkbox>
          </div>
        </div>
        <mo-list-item expandable prevent-expand class="agree-list" ref="listItems1" preventScrollWhenExpand>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <div class="ns-check">
                <mo-checkbox v-model="agreeCheckboxList" value="1" @input="fn_ClickedCheck(0)">1. 수집 · 이용에 관한 사항</mo-checkbox>
              </div>
            </div>
          </div>
          <template v-slot:expand>
            <div class="list-txtBox">
              <strong>■ 수집 · 이용 목적</strong>
              <p>보험계약 인수여부 결정을 위한 판단, 실손의료보험 중복 확인, 보험계약상담, 재무설계서비스, 보험료 할인, 건강진단 및 적부조사, 연금설계서비스, 통계 및 분석, 새로운 보험계약 체결시 기존 보험계약과의 중요사항 비교설명</p>
            </div>
            <div class="list-txtBox">
              <strong>■ 보유 · 이용기간</strong>
              <p class="txt-underline">동의일로부터 1년까지</p>
            </div>
            <div class="list-txtBox">
              <strong>■ 수집 · 이용 항목</strong>
              <p>
                -고유식별정보<br>
                <span class="txt-underline">주민등록번호, 외국인등록번호, 여권번호, 운전면허번호</span>
              </p>
              <div class="ns-check">
                <p>위 <span class="txt-underline">고유식별정보 수집·이용</span>에 동의하십니까?</p>
                <mo-checkbox v-model="agreeSubCheckboxList1[0]" @input="fn_SelectedSubCb(1, 0, 'agree')">동의함</mo-checkbox>
                <mo-checkbox v-model="dontAgreeSubCheckboxList1[0]" @input="fn_SelectedSubCb(1, 0, 'dontAgree')">동의안함</mo-checkbox>
              </div>
              <p class="pt20">
                -민감정보<br>
                <span class="txt-underline">피보험자의 건강·질병·상해에 관한 정보(진료기록, 상병명, 흡연여부, 신체정보 등)</span>
              </p>
              <div class="ns-check">
                <p>위 <span class="txt-underline">민감정보 수집·이용</span>에 동의하십니까?</p>
                <mo-checkbox v-model="agreeSubCheckboxList1[1]" @input="fn_SelectedSubCb(1, 1, 'agree')">동의함</mo-checkbox>
                <mo-checkbox v-model="dontAgreeSubCheckboxList1[1]" @input="fn_SelectedSubCb(1, 1, 'dontAgree')">동의안함</mo-checkbox>
              </div>
            </div>
            <div class="list-txtBox last">
              <strong>■ 개인(신용)정보</strong>
              <p>
                -일반개인정보<br>
                성명, 생년월일, 성별, 주소, 이메일, 휴대폰번호, 기타연락처(가족, 계약관계자 등), 국적, 사용언어, 직업, 운전여부, 국내거소신고번호, CI(연계정보), 체류자격
              </p>
              <p class="pt15">
                -신용거래정보<br>
                보험계약정보(당사, 타보험사[체신관서(우체국보험), 공제사업자 포함] 가입 상품종류, 기간, 보험가입금액, 보험료 등), 보험금 지급관련 정보(사고정보 및 보험사기정보 포함), 연금정보(국민/퇴직 등)
              </p>
              <p class="pt15">
                -신용능력정보<br>
                소득(건강보험료) 및 재산 정보 (연금 포함)
              </p>
              <div class="ns-check">
                <p>위 개인신용정보<span class="txt-underline">수집·이용</span>에 동의하십니까?</p>
                <mo-checkbox v-model="agreeSubCheckboxList1[2]" @input="fn_SelectedSubCb(1, 2, 'agree')">동의함</mo-checkbox>
                <mo-checkbox v-model="dontAgreeSubCheckboxList1[2]" @input="fn_SelectedSubCb(1, 2, 'dontAgree')">동의안함</mo-checkbox>
              </div>
              <span class="txt-point">
                * 연금정보는 연금설계 이용시에만 수집ㆍ이용되며, 서비스 미이용 시 동의하더라도 수집ㆍ이용되지 않습니다.
              </span>
            </div>
          </template>
        </mo-list-item>
        <mo-list-item expandable prevent-expand class="agree-list" ref="listItems2" preventScrollWhenExpand>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <div class="ns-check">
                <mo-checkbox v-model="agreeCheckboxList" value="2" @input="fn_ClickedCheck(1)">2. 조회에 관한 사항</mo-checkbox>
              </div>
            </div>
          </div>
          <template v-slot:expand>
            <div class="list-txtBox">
              <strong>■ 조회 대상 기관</strong>
              <p>신용정보원, 신용정보집중기관 및 보험요율산출기관, 은행연합회, 신용정보회사(NICE평가정보, 코리아크레딧뷰로 등)</p>
            </div>
            <div class="list-txtBox">
              <strong>■ 조회 목적</strong>
              <p>보험계약상담, 보험계약 인수여부 결정을 위한 판단(실손의료보험 중복 확인), 건강진단 및 적부조사, 새로운 보험계약 체결시 기존 보험계약과의 중요사항 비교 설명 등 법령상 의무수행 지원</p>
            </div>
            <div class="list-txtBox">
              <strong>■ 조회 동의의 효력기간</strong>
              <p>
                <span class="txt-underline">동의일로부터 1년까지</span>
              </p>
            </div>
            <div class="list-txtBox">
              <strong>■ 조회 항목</strong>
              <p>
                -고유식별정보<br>
                <span class="txt-underline">주민등록번호, 외국인등록번호, 여권번호, 운전면허번호</span>
              </p>
              <div class="ns-check">
                <p>위 <span class="txt-underline">고유식별정보 조회</span>에 동의하십니까?</p>
                <mo-checkbox v-model="agreeSubCheckboxList2[0]" @input="fn_SelectedSubCb(2, 0, 'agree')">동의함</mo-checkbox>
                <mo-checkbox v-model="dontAgreeSubCheckboxList2[0]" @input="fn_SelectedSubCb(2, 0, 'dontAgree')">동의안함</mo-checkbox>
              </div>
              <p class="pt20">
                -민감정보<br>
                <span class="txt-underline">피보험자의 건강·질병·상해에 관한 정보(진료기록, 상병명, 흡연여부, 신체정보 등)</span>
              </p>
              <div class="ns-check">
                <p>위 <span class="txt-underline">민감정보 조회</span>에 동의하십니까?</p>
                <mo-checkbox v-model="agreeSubCheckboxList2[1]" @input="fn_SelectedSubCb(2, 1, 'agree')">동의함</mo-checkbox>
                <mo-checkbox v-model="dontAgreeSubCheckboxList2[1]" @input="fn_SelectedSubCb(2, 1, 'dontAgree')">동의안함</mo-checkbox>
              </div>
            </div>
            <div class="list-txtBox last">
              <strong>■ 개인(신용)정보</strong>
              <p>
                -일반개인정보<br>
                성명, 주소, 이메일, 직장명
              </p>
              <p class="pt15">
                -신용거래정보<br>
                신용정보 주체의 대출/보증 등 거래내용, 신용도, 보험계약정보(보험가입금액, 보험료 등), 보험금 지급 정보(보험사기정보 포함)
              </p>
              <p class="pt15">
                -신용능력정보<br>
                은행연합회 등 금융기관의 연금저축/비과세/세금우대종합저축/비과세종합저축 가입 총액 및 내역
              </p>
              <div class="ns-check">
                <p>위 개인신용정보 <span class="txt-underline">조회</span>에 동의하십니까?</p>
                <mo-checkbox v-model="agreeSubCheckboxList2[2]" @input="fn_SelectedSubCb(2, 2, 'agree')">동의함</mo-checkbox>
                <mo-checkbox v-model="dontAgreeSubCheckboxList2[2]" @input="fn_SelectedSubCb(2, 2, 'dontAgree')">동의안함</mo-checkbox>
              </div> 
            </div>
          </template>
        </mo-list-item>
        <mo-list-item expandable prevent-expand class="agree-list" ref="listItems3" preventScrollWhenExpand>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <div class="ns-check">
                <mo-checkbox v-model="agreeCheckboxList" value="3" @input="fn_ClickedCheck(2)">3. 제공에 관한 사항</mo-checkbox>
              </div>
            </div>
          </div>
          <template v-slot:expand>
            <div class="list-txtBox">
              <strong>■ 제공받는 자</strong>
              <p>- 국내: 고객정보 관리 및 가입설계를 위한 처리에 동의한 가족구성원, 신용정보집중기관, 신용정보회사 (NICE평가정보, 코리아크레딧뷰로 등), 국내 재보험사,  보험요율산출기관, 한국정보통신진흥협회
              <br>
              - 국외: 국외 재보험사
              </p>
            </div>
            <div class="list-txtBox">
              <strong>■ 제공받는 자의 이용 목적</strong>
                <p>- 국내:
                  <br>&nbsp;· 고객정보 관리 및 가입설계에 동의한 가족 구성원 : 보험계약상담 결과확인
                  <br>&nbsp;· 신용정보집중기관 : 보험계약 및 보험금지급 관련 정보의 집중관리 및 활용 등 신용정보집중 기관의 업무와 새로운 보험계약 체결시 기존 보험계약과의 중요사항 비교 설명 등 법령상 의무수행 지원
                  <br>&nbsp;· 신용정보회사 : 당사 요청 정보 제공을 위한 대상자 식별
                  <br>&nbsp;· 국내 재보험사 : 재보험계약 상담, 체결 및 인수여부 결정을 위한 판단 목적
                  <br>&nbsp;· 한국정보통신진흥협회 : 외국인 고객의 국적정보 확인
                <br>
                - 국외:
                  <br>&nbsp;· 국외재보험사 : 보험계약의 인수심사와 공동인수, 재보험 가입 및 재보험금 청구
              </p>
            </div>
            <div class="list-txtBox">
              <strong>■ 제공 항목</strong>
              <p>
                -고유식별정보<br>
                <span class="txt-underline">주민등록번호, 외국인등록번호, 여권번호, 운전면허번호</span>
              </p>
              <div class="ns-check">
                <p>(국내) 위 <span class="txt-underline">고유식별정보 제공</span>에 동의하십니까?</p>
                <mo-checkbox v-model="agreeSubCheckboxList3[0]" @input="fn_SelectedSubCb(3, 0, 'agree')">동의함</mo-checkbox>
                <mo-checkbox v-model="dontAgreeSubCheckboxList3[0]" @input="fn_SelectedSubCb(3, 0, 'dontAgree')">동의안함</mo-checkbox>
              </div>
              <p class="pt20">
                -민감정보<br>
                <span class="txt-underline">피보험자의 건강·질병·상해에 관한 정보(진료기록, 상병명, 흡연여부 등)</span>
              </p>
              <div class="ns-check">
                <p>(국내) 위 <span class="txt-underline">민감정보 제공</span>에 동의하십니까?</p>
                <mo-checkbox v-model="agreeSubCheckboxList3[1]" @input="fn_SelectedSubCb(3, 1, 'agree')">동의함</mo-checkbox>
                <mo-checkbox v-model="dontAgreeSubCheckboxList3[1]" @input="fn_SelectedSubCb(3, 1, 'dontAgree')">동의안함</mo-checkbox>
              </div>
              <div class="ns-check">
                <p>(국외) 위 <span class="txt-underline">민감정보 제공</span>에 동의하십니까?</p>
                <mo-checkbox v-model="agreeSubCheckboxList3[3]" @input="fn_SelectedSubCb(3, 3, 'agree')">동의함</mo-checkbox>
                <mo-checkbox v-model="dontAgreeSubCheckboxList3[3]" @input="fn_SelectedSubCb(3, 3, 'dontAgree')">동의안함</mo-checkbox>
              </div>
            </div>
            <div class="list-txtBox">
              <strong>■ 개인(신용)정보</strong>
              <p>
                -일반개인정보<br>
                성명, 생년월일, 성별, 주소, 전화번호,  이메일 , 직업, 직장, 국적
              </p>
              <p class="pt15">
                -신용거래정보<br>
                보험계약정보(상품종류, 기간, 보험가입금액, 보험료 등), 보험금 지급 관련 정보(사고정보 포함), 계약 전 알릴 의무사항
              </p>
              <div class="ns-check">
                <p>(국내) 위 <span class="txt-underline">개인(신용)정보 제공</span>에 동의하십니까?</p>
                <mo-checkbox v-model="agreeSubCheckboxList3[2]" @input="fn_SelectedSubCb(3, 2, 'agree')">동의함</mo-checkbox>
                <mo-checkbox v-model="dontAgreeSubCheckboxList3[2]" @input="fn_SelectedSubCb(3, 2, 'dontAgree')">동의안함</mo-checkbox>
              </div>
              <div class="ns-check">
                <p>(국외) 위 <span class="txt-underline">개인(신용)정보 제공</span>에 동의하십니까?</p>
                <mo-checkbox v-model="agreeSubCheckboxList3[4]" @input="fn_SelectedSubCb(3, 4, 'agree')">동의함</mo-checkbox>
                <mo-checkbox v-model="dontAgreeSubCheckboxList3[4]" @input="fn_SelectedSubCb(3, 4, 'dontAgree')">동의안함</mo-checkbox>
              </div>
            </div>
            <div class="list-txtBox last">
              <strong>■ 보유·이용기간 (국내/국외)</strong>
              <p>
                <span class="txt-underline">동의일로부터 1년까지</span>
                <br>
                <span class="txt-point">
                  * 외국 재보험사의 국내 지점이 보험계약 인수여부 판단 지원 등 업무를 위탁하기 위한
                  경우 별동의 동의없이 외국 소재 본점에 귀하의 정보를 이전할 수 있습니다.
                </span>
                <span class="txt-point">
                * 업무위탁을 목적으로 개인(신용)정보 처리하는 경우 별도의 동의 없이 수탁자에게 귀하의 정보를 제공할 수 있습니다.
                (홈페이지 [www.samsunglife.com]에서 확인 가능
              </span>
              </p>
            </div>
          </template>
        </mo-list-item>
        <div class="agree-box-desc"><span>CISO 심의필[2024-158]</span></div>
        <div class="ns-height100"></div>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
        <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClickPrev">이전</mo-button>
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="!isAllAgree" @click="fn_ClickNext">다음</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
      // 현재 화면명
      name: 'MSPCM002M',
      // 현재 화면 ID
      screenId: 'MSPCM002M',
      components: {
      },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        title: '필수컨설팅동의', // 화면명
        agreeTypeMcnst: false, // 필수컨설팅동의
        dontAgreeCheckbox: false,
        isAllAgree: false,
        agreeSubCheckboxList1: [false, false, false],
        agreeSubCheckboxList2: [false, false, false],
        agreeSubCheckboxList3: [false, false, false, false, false],
        dontAgreeSubCheckboxList1: [false, false, false],
        dontAgreeSubCheckboxList2: [false, false, false],
        dontAgreeSubCheckboxList3: [false, false, false, false, false],
        agreeCheckboxList: [],
        infoPrcusCnsntTypCd: '', // 정보활용동의 유형 코드
        
        pCustNm: ''
      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    /** computed 함수 정의 영역 */
    computed: {
    },
    
    /** watch 정의 영역 */
    watch: {
      isAllAgree(newValue) {
        if (newValue) {
          this.dontAgreeCheckbox = false

          // this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
            for(let i = 1 ; i < 4; i++){
              this.$refs['listItems'+i].collapse() // 아코디언 접기 처리
            }
          // })
        }
      },
      dontAgreeCheckbox(newValue) {
        if (newValue) {
          this.isAllAgree = false
        }
      },
      agreeCheckboxList(newVal) {
        this.isAllAgree = newVal.length === 3
      }

    },

    /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
    created() {
      this.pCustNm = !this.$bizUtil.isEmpty(this.$route.params.custNm) ? this.$route.params.custNm : ''
      if(!_.isEmpty(this.$route.params) && !this.$bizUtil.isEmpty(this.$route.params.custNm)){
        this.infoPrcusCnsntTypCd =  this.$route.params.infoPrcusCnsntTypCd
        
        //필수컨설팅동의 데이터 가 하나라도 있을 경우 전체 동의 설정
        if(this.$route.params.agreeTypeMcnst !== undefined && 
           this.$route.params.agreeTypeMcnst === true) {
          this.agreeCheckboxList = ['1', '2', '3']
          this.agreeSubCheckboxList1 = [true, true, true]
          this.agreeSubCheckboxList2 = [true, true, true]
          this.agreeSubCheckboxList3 = [true, true, true, true, true]
          this.dontAgreeSubCheckboxList1 = [false, false, false]
          this.dontAgreeSubCheckboxList2 = [false, false, false]
          this.dontAgreeSubCheckboxList3 = [false, false, false, false, false]
        }
      } else {
        // 필컨 정보
        let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo

        if (!this.$bizUtil.isEmpty(storeCustInfo.infoPrcusCnsntTypCd)) {
          this.infoPrcusCnsntTypCd = storeCustInfo.infoPrcusCnsntTypCd
        }
        this.pCustNm = !this.$bizUtil.isEmpty(storeCustInfo.custNm) ? storeCustInfo.custNm : ''
      }

      this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
        for(let i = 1 ; i < 5; i++){
          this.$refs['listItems'+i].expand() // 아코디언 펼침 처리
        }
      });

      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickPrev)
    },
    activated () {
      this.fn_init()
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPCM002M')
    },
    beforeDestroy () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickPrev)
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_init
      * 설명       : 초기화
      ******************************************************************************/
      fn_init () {
        this.agreeCheckboxList = []
        this.agreeSubCheckboxList1 = [false, false, false]
        this.agreeSubCheckboxList2 = [false, false, false]
        this.agreeSubCheckboxList3 = [false, false, false, false, false]
        this.dontAgreeSubCheckboxList1 = [false, false, false]
        this.dontAgreeSubCheckboxList2 = [false, false, false]
        this.dontAgreeSubCheckboxList3 = [false, false, false, false, false]
        this.pCustNm = this.$route.params.custNm
      },
      /******************************************************************************
      * Function명 : fn_SelectAgreeAll
      * 설명       : 전체동의 선택 시
      ******************************************************************************/
      fn_SelectAgreeAll () {
        this.agreeCheckboxList = this.isAllAgree ? ['1', '2', '3'] : []

        if (this.isAllAgree) {
          
          this.agreeSubCheckboxList1 = [true, true, true]
          this.agreeSubCheckboxList2 = [true, true, true]
          this.agreeSubCheckboxList3 = [true, true, true, true, true]
          this.dontAgreeSubCheckboxList1 = [false, false, false]
          this.dontAgreeSubCheckboxList2 = [false, false, false]
          this.dontAgreeSubCheckboxList3 = [false, false, false, false, false]
        } else {
          
          this.agreeSubCheckboxList1 = [false, false, false]
          this.agreeSubCheckboxList2 = [false, false, false]
          this.agreeSubCheckboxList3 = [false, false, false, false, false]
        }
      },
      /******************************************************************************
      * Function명 : fn_CancelAgreeAll
      * 설명       : 동의안함 선택 시
      ******************************************************************************/
      fn_CancelAgreeAll () {
        if (this.dontAgreeCheckbox) {
          this.agreeCheckboxList = []
          this.agreeSubCheckboxList1 = [false, false, false]
          this.agreeSubCheckboxList2 = [false, false, false]
          this.agreeSubCheckboxList3 = [false, false, false, false, false]
          this.dontAgreeSubCheckboxList1 = [true, true, true]
          this.dontAgreeSubCheckboxList2 = [true, true, true]
          this.dontAgreeSubCheckboxList3 = [true, true, true, true, true]
        } else {
          this.dontAgreeSubCheckboxList1 = [false, false, false]
          this.dontAgreeSubCheckboxList2 = [false, false, false]
          this.dontAgreeSubCheckboxList3 = [false, false, false, false, false]
        }
      },
      /******************************************************************************
      * Function명 : fn_ClickedCheck
      * 설명       : 제목별 동의함, 동의안함 선택 시
      ******************************************************************************/
      fn_ClickedCheck (v) {
        if (this.agreeCheckboxList.includes((v + 1).toString())) {
          
          if (v === 0) {
            this.agreeSubCheckboxList1 = [true, true, true]
            this.dontAgreeSubCheckboxList1 = [false, false, false]
          } else if (v === 1) {
            this.agreeSubCheckboxList2 = [true, true, true]
            this.dontAgreeSubCheckboxList2 = [false, false, false]
          } else if (v === 2) {
            this.agreeSubCheckboxList3 = [true, true, true, true, true]
            this.dontAgreeSubCheckboxList3 = [false, false, false, false, false]
          }
        } else {
          
          if (v === 0) {
            this.agreeSubCheckboxList1 = [false, false, false]
          } else if (v === 1) {
            this.agreeSubCheckboxList2 = [false, false, false]
          } else if (v === 2) {
            this.agreeSubCheckboxList3 = [false, false, false, false, false]
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_SelectedSubCb
      * 설명       : 상세 동의함, 동의안함 선택 시
      ******************************************************************************/
      fn_SelectedSubCb (value, idx, flag) {
        if (value === 1) {
          if (flag === 'agree') {
            this.agreeSubCheckboxList1[idx] = true
            this.dontAgreeSubCheckboxList1[idx] = false
          } else {
            this.agreeSubCheckboxList1[idx] = false
            this.dontAgreeSubCheckboxList1[idx] = true
          }

          if (this.agreeSubCheckboxList1.indexOf(false) < 0) { // 하위 항목 모두 클릭 시
            if (this.agreeCheckboxList.indexOf('1') < 0) {
              this.agreeCheckboxList.push('1')
            }
          } else {
            let lv_idx = this.agreeCheckboxList.indexOf('1')
            if (lv_idx > -1) {
              this.agreeCheckboxList.splice(lv_idx, 1)
            }
          }
        } else if (value === 2) {
          if (flag === 'agree') {
            this.agreeSubCheckboxList2[idx] = true
            this.dontAgreeSubCheckboxList2[idx] = false
          } else {
            this.agreeSubCheckboxList2[idx] = false
            this.dontAgreeSubCheckboxList2[idx] = true
          }

          if (this.agreeSubCheckboxList2.indexOf(false) < 0) { // 하위 항목 모두 클릭 시
            if (this.agreeCheckboxList.indexOf('2') < 0) {
              this.agreeCheckboxList.push('2')
            }
          } else {
            let lv_idx = this.agreeCheckboxList.indexOf('2')
            if (lv_idx > -1) {
              this.agreeCheckboxList.splice(lv_idx, 1)
            }
          }
        } else if (value === 3) {
          if (flag === 'agree') {
            this.agreeSubCheckboxList3[idx] = true
            this.dontAgreeSubCheckboxList3[idx] = false
          } else {
            this.agreeSubCheckboxList3[idx] = false
            this.dontAgreeSubCheckboxList3[idx] = true
          }

          if (this.agreeSubCheckboxList3.indexOf(false) < 0) { // 하위 항목 모두 클릭 시
            if (this.agreeCheckboxList.indexOf('3') < 0) {
              this.agreeCheckboxList.push('3')
            }
          } else {
            let lv_idx = this.agreeCheckboxList.indexOf('3')
            if (lv_idx > -1) {
              this.agreeCheckboxList.splice(lv_idx, 1)
            }
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_ClickNext
      * 설명       : 다음 버튼 클릭 시 호출 - (마컨 동의 or 인증 화면으로 이동)
      ******************************************************************************/
      fn_ClickNext () {
        if(this.infoPrcusCnsntTypCd === '18'){
          this.fn_MoveMspcm003mPage()
        }else{
          this.fn_MoveMspcm004mPage()
        }

      },
      /******************************************************************************
      * Function명 : fn_ClickPrev
      * 설명       : 이전 버튼 클릭 시 호출 - (고객동의목록 화면 이동)
      ******************************************************************************/
      fn_ClickPrev () {
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)

        this.$router.go(-1)
        return
        this.fn_MoveMspcm001mPage()
      },
      /******************************************************************************
      * Function명 : fn_MoveMspcm001mPage
      * 설명       : MSPCM001M화면으로 이동 (마케팅 동의)
      ******************************************************************************/
      fn_MoveMspcm001mPage () {
        // Store 페이지 카운트
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)

        // 현재 화면을 history 배열에 저장
        this.$commonUtil.setLastScreen(this.$options.screenId)
        this.$router.push(
          {
            name: 'MSPCM001M',
            params: {
              name: 'MSPCM002M',
              infoPrcusCnsntTypCd: this.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨, 마동 둘다 : 18
              custNm: this.pCustNm
            }
          }
        )
      },
      /******************************************************************************
      * Function명 : fn_MoveMspcm003mPage
      * 설명       : MSPCM003M화면으로 이동 (마케팅 동의)
      ******************************************************************************/
      fn_MoveMspcm003mPage () {
        // Store 페이지 카운트
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)

        let pParam = {
              name: 'MSPCM002M',
              agreeTypeMcnst: true, //필컨 정보 여부
              infoPrcusCnsntTypCd: this.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨, 마동 둘다 : 18
              custNm: this.pCustNm
            }

        // 필컨 정보 저장
        let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo
        storeCustInfo = Object.assign(storeCustInfo, pParam)
        window.vue.getStore('cmStore').dispatch('ADD', storeCustInfo)

        // 현재 화면을 history 배열에 저장
        this.$commonUtil.setLastScreen(this.$options.screenId)
        this.$router.push(
          {
            name: 'MSPCM003M',
            params: pParam
          }
        )
      },
      /******************************************************************************
      * Function명 : fn_MoveMspcm004mPage
      * 설명       : MSPCM004M화면으로 이동 (인증 화면)
      ******************************************************************************/
      fn_MoveMspcm004mPage () {
        // Store 페이지 카운트
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)

        let pParam = {
              name: 'MSPCM002M',
              agreeTypeMcnst: true, //필컨 정보
              infoPrcusCnsntTypCd: this.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨, 마동 둘다 : 18
              custNm: this.pCustNm
            }

        // 필컨 정보 저장
        let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo
        storeCustInfo = Object.assign(storeCustInfo, pParam)
        window.vue.getStore('cmStore').dispatch('ADD', storeCustInfo)

        // 현재 화면을 history 배열에 저장
        this.$commonUtil.setLastScreen(this.$options.screenId)
        this.$router.push(
          {
            name: 'MSPCM004M',
            params: pParam
          }
        )
      },
      /******************************************************************************
      * Function명: _Back
      * 설명: 헤더 영역 Back Button 파라미터 넘기기
      *           => 사용안함
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      _Back () {
        return
        let alertMsg = '고객동의를 종료하시겠습니까?<br/> 동의종료 선택시 작성된 고객정보는 저장되지 않으며, 고객동의는 완료되지 않습니다.'
        let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
        let preSrnId = !this.$bizUtil.isEmpty(preSrnObj.srnId) ? preSrnObj.srnId : 'MSPBC002M'

        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            content: alertMsg,
            title_pos_btn: '나가기'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
              
              window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화
              this.$router.push({ name: preSrnId, params: preSrnObj.rtnParam })
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })

      },
    } //methods end
  };
</script>
<style scoped>
</style>